/* eslint-disable */
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig
} from 'graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type RequireFields<T, K extends keyof T> = Omit<T, K> &
  { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An `ISO-8601` compliant DateTime Scalar, e.g. 2022-06-01T10:15:00.000 */
  DateTime: any
  _FieldSet: any
}

export type Execution = {
  __typename?: 'Execution'
  arn?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['String']>
  revision?: Maybe<Scalars['Int']>
  startDateTime?: Maybe<Scalars['DateTime']>
  status?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['String']>
}

export type ListAllScheduledTaskResult = {
  __typename?: 'ListAllScheduledTaskResult'
  results?: Maybe<Array<Maybe<ScheduledTaskItem>>>
}

export type ListAllScheduledTasksResponse =
  | ListAllScheduledTaskResult
  | ScheduledTaskManagerError

export type Mutation = {
  __typename?: 'Mutation'
  runScheduledTask?: Maybe<RunTaskResponse>
}

export type MutationRunScheduledTaskArgs = {
  runTaskInput: RunTaskInput
}

export type Query = {
  __typename?: 'Query'
  _service: _Service
  scheduledTask?: Maybe<ScheduledTaskResponse>
  scheduledTasks?: Maybe<ListAllScheduledTasksResponse>
}

export type QueryScheduledTaskArgs = {
  numNextExecutionTimes?: InputMaybe<Scalars['Int']>
  taskName: Scalars['String']
}

export type RunTaskInput = {
  executionPayload?: InputMaybe<Scalars['String']>
  issueId?: InputMaybe<Scalars['String']>
  runName: Scalars['String']
  taskName: Scalars['String']
}

export type RunTaskResponse = RunTaskResult | ScheduledTaskManagerError

export type RunTaskResult = {
  __typename?: 'RunTaskResult'
  execution?: Maybe<Execution>
}

export type ScheduledTaskItem = {
  __typename?: 'ScheduledTaskItem'
  cronExpression?: Maybe<Scalars['String']>
  departments: Array<Scalars['String']>
  description: Scalars['String']
  executions?: Maybe<Array<Execution>>
  githubTeamName?: Maybe<Scalars['String']>
  name: Scalars['String']
  nextExecutionTimes?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  readOnly: Scalars['Boolean']
  repositoryUrl?: Maybe<Scalars['String']>
  stateMachineArn?: Maybe<Scalars['String']>
}

export type ScheduledTaskItemExecutionsArgs = {
  maxExecutionResults?: InputMaybe<Scalars['Int']>
}

export type ScheduledTaskManagerError = {
  __typename?: 'ScheduledTaskManagerError'
  code: ScheduledTaskManagerErrorCode
  message?: Maybe<Scalars['String']>
}

export enum ScheduledTaskManagerErrorCode {
  BadRequest = 'BAD_REQUEST',
  Conflict = 'CONFLICT',
  Forbidden = 'FORBIDDEN',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotFound = 'NOT_FOUND'
}

export type ScheduledTaskResponse =
  | ScheduledTaskItem
  | ScheduledTaskManagerError

export type _Service = {
  __typename?: '_Service'
  sdl: Scalars['String']
}

export type RunScheduledTaskMutationVariables = Exact<{
  input: RunTaskInput
}>

export type RunScheduledTaskMutation = {
  __typename?: 'Mutation'
  runScheduledTask?:
    | {
        __typename?: 'RunTaskResult'
        execution?: {
          __typename?: 'Execution'
          name?: string | null
          startDateTime?: any | null
          status?: string | null
          revision?: number | null
          arn?: string | null
          payload?: string | null
          user?: string | null
        } | null
      }
    | {
        __typename?: 'ScheduledTaskManagerError'
        code: ScheduledTaskManagerErrorCode
        message?: string | null
      }
    | null
}

export type AllScheduledTasksQueryVariables = Exact<{ [key: string]: never }>

export type AllScheduledTasksQuery = {
  __typename?: 'Query'
  scheduledTasks?:
    | {
        __typename?: 'ListAllScheduledTaskResult'
        results?: Array<{
          __typename?: 'ScheduledTaskItem'
          name: string
          description: string
          readOnly: boolean
          departments: Array<string>
        } | null> | null
      }
    | {
        __typename?: 'ScheduledTaskManagerError'
        code: ScheduledTaskManagerErrorCode
        message?: string | null
      }
    | null
}

export type ScheduledTaskQueryVariables = Exact<{
  input: Scalars['String']
  maxExecutionResults?: InputMaybe<Scalars['Int']>
}>

export type ScheduledTaskQuery = {
  __typename?: 'Query'
  scheduledTask?:
    | {
        __typename?: 'ScheduledTaskItem'
        name: string
        description: string
        readOnly: boolean
        departments: Array<string>
        githubTeamName?: string | null
        repositoryUrl?: string | null
        nextExecutionTimes?: Array<any | null> | null
        cronExpression?: string | null
        executions?: Array<{
          __typename?: 'Execution'
          name?: string | null
          startDateTime?: any | null
          status?: string | null
          revision?: number | null
          arn?: string | null
          payload?: string | null
          user?: string | null
        }> | null
      }
    | {
        __typename?: 'ScheduledTaskManagerError'
        code: ScheduledTaskManagerErrorCode
        message?: string | null
      }
    | null
}

export const RunScheduledTaskDocument = {
  __meta__: { hash: '288ed59d7e83f7dbca55ee0ced097892527b172e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RunScheduledTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RunTaskInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'runScheduledTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'runTaskInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RunTaskResult' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'execution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDateTime' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'revision' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'arn' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'payload' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ScheduledTaskManagerError' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RunScheduledTaskMutation,
  RunScheduledTaskMutationVariables
>
export const AllScheduledTasksDocument = {
  __meta__: { hash: 'a549e7e89d8d49ffdbb2b0c82c18fa0749e09093' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllScheduledTasks' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduledTasks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ListAllScheduledTaskResult' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'results' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'readOnly' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'departments' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ScheduledTaskManagerError' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AllScheduledTasksQuery,
  AllScheduledTasksQueryVariables
>
export const ScheduledTaskDocument = {
  __meta__: { hash: 'accefc96aecf6aa1ce94ce929bc6cbaf6b4d581e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ScheduledTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxExecutionResults' }
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduledTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ScheduledTaskItem' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'readOnly' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'departments' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'executions' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'maxExecutionResults'
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'maxExecutionResults'
                              }
                            }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDateTime' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'revision' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'arn' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'payload' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'githubTeamName' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'repositoryUrl' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextExecutionTimes' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cronExpression' }
                      }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ScheduledTaskManagerError' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ScheduledTaskQuery, ScheduledTaskQueryVariables>

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>
  Execution: ResolverTypeWrapper<Execution>
  String: ResolverTypeWrapper<Scalars['String']>
  Int: ResolverTypeWrapper<Scalars['Int']>
  ListAllScheduledTaskResult: ResolverTypeWrapper<ListAllScheduledTaskResult>
  ListAllScheduledTasksResponse:
    | ResolversTypes['ListAllScheduledTaskResult']
    | ResolversTypes['ScheduledTaskManagerError']
  Mutation: ResolverTypeWrapper<{}>
  Query: ResolverTypeWrapper<{}>
  RunTaskInput: RunTaskInput
  RunTaskResponse:
    | ResolversTypes['RunTaskResult']
    | ResolversTypes['ScheduledTaskManagerError']
  RunTaskResult: ResolverTypeWrapper<RunTaskResult>
  ScheduledTaskItem: ResolverTypeWrapper<ScheduledTaskItem>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  ScheduledTaskManagerError: ResolverTypeWrapper<ScheduledTaskManagerError>
  ScheduledTaskManagerErrorCode: ScheduledTaskManagerErrorCode
  ScheduledTaskResponse:
    | ResolversTypes['ScheduledTaskItem']
    | ResolversTypes['ScheduledTaskManagerError']
  _FieldSet: ResolverTypeWrapper<Scalars['_FieldSet']>
  _Service: ResolverTypeWrapper<_Service>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  DateTime: Scalars['DateTime']
  Execution: Execution
  String: Scalars['String']
  Int: Scalars['Int']
  ListAllScheduledTaskResult: ListAllScheduledTaskResult
  ListAllScheduledTasksResponse:
    | ResolversParentTypes['ListAllScheduledTaskResult']
    | ResolversParentTypes['ScheduledTaskManagerError']
  Mutation: {}
  Query: {}
  RunTaskInput: RunTaskInput
  RunTaskResponse:
    | ResolversParentTypes['RunTaskResult']
    | ResolversParentTypes['ScheduledTaskManagerError']
  RunTaskResult: RunTaskResult
  ScheduledTaskItem: ScheduledTaskItem
  Boolean: Scalars['Boolean']
  ScheduledTaskManagerError: ScheduledTaskManagerError
  ScheduledTaskResponse:
    | ResolversParentTypes['ScheduledTaskItem']
    | ResolversParentTypes['ScheduledTaskManagerError']
  _FieldSet: Scalars['_FieldSet']
  _Service: _Service
}

export type ExtendsDirectiveArgs = {}

export type ExtendsDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ExtendsDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ExternalDirectiveArgs = {}

export type ExternalDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ExternalDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type KeyDirectiveArgs = {
  fields: Scalars['_FieldSet']
}

export type KeyDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = KeyDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ProvidesDirectiveArgs = {
  fields: Scalars['_FieldSet']
}

export type ProvidesDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ProvidesDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type RequiresDirectiveArgs = {
  fields: Scalars['_FieldSet']
}

export type RequiresDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = RequiresDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime'
}

export type ExecutionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Execution'] = ResolversParentTypes['Execution']
> = {
  arn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  payload?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  revision?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  startDateTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ListAllScheduledTaskResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListAllScheduledTaskResult'] = ResolversParentTypes['ListAllScheduledTaskResult']
> = {
  results?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ScheduledTaskItem']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ListAllScheduledTasksResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListAllScheduledTasksResponse'] = ResolversParentTypes['ListAllScheduledTasksResponse']
> = {
  __resolveType: TypeResolveFn<
    'ListAllScheduledTaskResult' | 'ScheduledTaskManagerError',
    ParentType,
    ContextType
  >
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  runScheduledTask?: Resolver<
    Maybe<ResolversTypes['RunTaskResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationRunScheduledTaskArgs, 'runTaskInput'>
  >
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  _service?: Resolver<ResolversTypes['_Service'], ParentType, ContextType>
  scheduledTask?: Resolver<
    Maybe<ResolversTypes['ScheduledTaskResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryScheduledTaskArgs, 'numNextExecutionTimes' | 'taskName'>
  >
  scheduledTasks?: Resolver<
    Maybe<ResolversTypes['ListAllScheduledTasksResponse']>,
    ParentType,
    ContextType
  >
}

export type RunTaskResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RunTaskResponse'] = ResolversParentTypes['RunTaskResponse']
> = {
  __resolveType: TypeResolveFn<
    'RunTaskResult' | 'ScheduledTaskManagerError',
    ParentType,
    ContextType
  >
}

export type RunTaskResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RunTaskResult'] = ResolversParentTypes['RunTaskResult']
> = {
  execution?: Resolver<
    Maybe<ResolversTypes['Execution']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScheduledTaskItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledTaskItem'] = ResolversParentTypes['ScheduledTaskItem']
> = {
  cronExpression?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  departments?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  executions?: Resolver<
    Maybe<Array<ResolversTypes['Execution']>>,
    ParentType,
    ContextType,
    RequireFields<ScheduledTaskItemExecutionsArgs, 'maxExecutionResults'>
  >
  githubTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nextExecutionTimes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DateTime']>>>,
    ParentType,
    ContextType
  >
  readOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  repositoryUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  stateMachineArn?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScheduledTaskManagerErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledTaskManagerError'] = ResolversParentTypes['ScheduledTaskManagerError']
> = {
  code?: Resolver<
    ResolversTypes['ScheduledTaskManagerErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScheduledTaskResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledTaskResponse'] = ResolversParentTypes['ScheduledTaskResponse']
> = {
  __resolveType: TypeResolveFn<
    'ScheduledTaskItem' | 'ScheduledTaskManagerError',
    ParentType,
    ContextType
  >
}

export interface _FieldSetScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['_FieldSet'], any> {
  name: '_FieldSet'
}

export type _ServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['_Service'] = ResolversParentTypes['_Service']
> = {
  sdl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  DateTime?: GraphQLScalarType
  Execution?: ExecutionResolvers<ContextType>
  ListAllScheduledTaskResult?: ListAllScheduledTaskResultResolvers<ContextType>
  ListAllScheduledTasksResponse?: ListAllScheduledTasksResponseResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  RunTaskResponse?: RunTaskResponseResolvers<ContextType>
  RunTaskResult?: RunTaskResultResolvers<ContextType>
  ScheduledTaskItem?: ScheduledTaskItemResolvers<ContextType>
  ScheduledTaskManagerError?: ScheduledTaskManagerErrorResolvers<ContextType>
  ScheduledTaskResponse?: ScheduledTaskResponseResolvers<ContextType>
  _FieldSet?: GraphQLScalarType
  _Service?: _ServiceResolvers<ContextType>
}

export type DirectiveResolvers<ContextType = any> = {
  extends?: ExtendsDirectiveResolver<any, any, ContextType>
  external?: ExternalDirectiveResolver<any, any, ContextType>
  key?: KeyDirectiveResolver<any, any, ContextType>
  provides?: ProvidesDirectiveResolver<any, any, ContextType>
  requires?: RequiresDirectiveResolver<any, any, ContextType>
}
