import * as React from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'

/**
 * SeeAlsoAlert component
 */
export const SeeAlsoAlert = () => {
  return (
    <Alert
      testId='see-also-alert'
      className='mb-4 w-full'
      title='See also'
      variant='info'
    >
      <ul className='list-disc ml-5 '>
        <li>
          <a
            href='https://toasttab.atlassian.net/wiki/spaces/RD/pages/2992832513/Creating+a+new+ECS+scheduled+task'
            target='_blank'
            rel='noreferrer'
          >
            <button className='inline-link-inherit'>
              Creating a new ECS scheduled task
            </button>
          </a>
        </li>
        <li>
          <a
            href='https://toasttab.atlassian.net/wiki/spaces/TSC/pages/3610313466/Runbook+Trigger+Scheduled+Task+Executions'
            target='_blank'
            rel='noreferrer'
          >
            <button className='inline-link-inherit'>
              Runbook: Trigger Scheduled Task Executions
            </button>
          </a>
        </li>
        <li>
          <a
            href='https://forms.gle/jTvTV2e5QD3CZc4h7'
            target='_blank'
            rel='noreferrer'
          >
            <button className='inline-link-inherit'>
              Share your feedback!
            </button>
          </a>
        </li>
      </ul>
    </Alert>
  )
}
