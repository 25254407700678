import * as React from 'react'
import { Body, Head, HeadingCell, Row, Table } from '@toasttab/buffet-pui-table'
import { SkeletonRow } from '@local/list-row'
import { ShowPageExecutionsRow } from '@local/show-page-executions-row'
import { Execution } from '@local/interfaces'
import { Panel } from '@toasttab/buffet-pui-config-templates'

export interface ShowPageExecutionsTableProps {
  testId?: string | number
  isLoading: boolean
  executions: Execution[]
  taskName: string
}

/**
 * ShowPageExecutionsTable component
 */
export const ShowPageExecutionsTable = (
  props: ShowPageExecutionsTableProps
) => {
  if (props.isLoading) {
    return executionsTableWithBody(<SkeletonRow cellCount={6} />, props)
  } else {
    const sortedExecutions = [...props.executions].sort((a, b) =>
      new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime()
    )
    return executionsTableWithBody(
      sortedExecutions.map((execution) => (
        <ShowPageExecutionsRow
          testId={execution.name}
          key={execution.name}
          execution={execution}
          taskName={props.taskName}
        ></ShowPageExecutionsRow>
      )),
      props
    )
  }
}

function executionsTableWithBody(
  bodyData: any,
  props: ShowPageExecutionsTableProps
) {
  return (
    <Panel>
      <h1 className={'type-headline-4'}>Executions</h1>
      <div data-testid={props?.testId} className='p-4 space-y-2 type-default'>
        <Table className='table table-striped table-bordered'>
          <Head data-testid='executionsTableHeader'>
            <Row>
              <HeadingCell>Execution Name</HeadingCell>
              <HeadingCell>Start Time</HeadingCell>
              <HeadingCell>Revision</HeadingCell>
              <HeadingCell>Status</HeadingCell>
              <HeadingCell>Logs</HeadingCell>
              <HeadingCell>Details</HeadingCell>
            </Row>
          </Head>
          <Body>{bodyData}</Body>
        </Table>
      </div>
    </Panel>
  )
}
