import { gql } from '@apollo/client'

export const ALL_SCHEDULED_TASK_ITEMS = gql`
  query AllScheduledTasks {
    scheduledTasks {
      ... on ListAllScheduledTaskResult {
        results {
          name
          description
          readOnly
          departments
        }
      }
      ... on ScheduledTaskManagerError {
        code
        message
      }
    }
  }
`
