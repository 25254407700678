import * as React from 'react'
import {
  HeadingGroup,
  LayoutProvider,
  Page,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { SeeAlsoAlert } from '@local/see-also-alert'
import { TaskListPanel } from '../TaskListPanel'

export interface ListPageProps {
  testId?: string | number
}

/**
 * ListPage component
 */
export function ListPage({ testId = 'ListPage' }: ListPageProps) {
  return (
    <LayoutProvider disableMaxWidth>
      <Page>
        <PageHeader>
          <HeadingGroup>
            <Title>Scheduled Task Manager</Title>
          </HeadingGroup>
        </PageHeader>
        <PageBody>
          <SeeAlsoAlert />
          <TaskListPanel />
        </PageBody>
      </Page>
    </LayoutProvider>
  )
}
