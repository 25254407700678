import { Panel } from '@toasttab/buffet-pui-config-templates'
import { Body, Head, HeadingCell, Row, Table } from '@toasttab/buffet-pui-table'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { TaskListFilterControls } from '../TaskListFilterControls'
import { ListTable } from '@local/list-table'
import {
  ALL_SCHEDULED_TASK_ITEMS,
  AllScheduledTasksResult,
  isForbiddenError,
  isScheduledTaskManagerError,
  isScheduledTasksResult,
  ScheduledTaskManagerError
} from '@local/scheduled-task-manager-client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useSentry } from 'banquet-runtime-modules'
import { useQuery } from '@apollo/client'
import { TaskFilter, TRUE_FILTER } from '../TaskFilter'
import { ScheduledTaskListItem } from '@local/interfaces'
import { ErrorBody403, ErrorBodyUnknown } from '@local/error-bodies'
import { formatDepartment } from '@local/content-common/src/contentCommon/contentCommon'

export interface TaskListPanelProps {
  testId?: string | number
}

export const TaskListPanel = (props: TaskListPanelProps) => {
  const { showErrorSnackBar } = useSnackBar()
  const { captureException } = useSentry()
  const { data, loading, error } = useQuery(ALL_SCHEDULED_TASK_ITEMS, {
    onError: (error) => {
      handleError(error)
    },
    onCompleted: (data) => {
      if (isScheduledTaskManagerError(data.scheduledTasks)) {
        handleError(data?.scheduledTasks as ScheduledTaskManagerError)
      }
    }
  })

  const [taskFilter, setTaskFilter] = useState<TaskFilter>(TRUE_FILTER)
  // useCallback prevents an infinite loop of re-rendering
  const handleFilterChanged = useCallback((taskFilter: TaskFilter) => {
    setTaskFilter(taskFilter)
  }, [])

  let filteredTasks: ScheduledTaskListItem[] = []
  let departments = new Set<string>()

  if (isScheduledTasksResult(data)) {
    let tasks = (data.scheduledTasks as AllScheduledTasksResult).results.map(
      (t) => formatTaskDepartment(t)
    )
    filteredTasks = tasks.filter((task) => taskFilter.filter(task))
    tasks.flatMap((t) => t.departments).forEach((d) => departments.add(d))
  }

  return isForbiddenError(error) ? (
    <ErrorBody403 error={error} />
  ) : error || isScheduledTaskManagerError(error) ? (
    <ErrorBodyUnknown error={error} />
  ) : (
    <Panel testId={'task-list-panel'}>
      <TaskListFilterControls
        testId={'task-list-filter-controls'}
        departments={[...departments]}
        onFilterChanged={handleFilterChanged}
      />
      <div data-testid={props.testId} className='p-4 space-y-2 type-default'>
        <Table className='table table-striped table-bordered'>
          <Head data-testid='taskTableHeader'>
            <Row>
              <HeadingCell>Task Name</HeadingCell>
              <HeadingCell>Description</HeadingCell>
              <HeadingCell>Departments</HeadingCell>
              <HeadingCell className='text-center'>Run Task Access</HeadingCell>
            </Row>
          </Head>
          <Body>
            <ListTable loading={loading} tasks={filteredTasks} />
          </Body>
        </Table>
      </div>
    </Panel>
  )

  function handleError(error?: Error | ScheduledTaskManagerError) {
    showErrorSnackBar(
      `An error has occurred while fetching the scheduled tasks: ${error?.message}`,
      {
        testId: 'getScheduledTasksErrorSnackBar'
      }
    )
    captureException(
      new Error(`[Get scheduled tasks]: Error: ${error?.message}`)
    )
  }

  function formatTaskDepartment(
    task: ScheduledTaskListItem
  ): ScheduledTaskListItem {
    let t = { ...task }
    t.departments = t.departments.map((d) => formatDepartment(d))
    return t
  }
}
