import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { App } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-app',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],
  sentry: {
    publicKey: '2501c60e841240fba82f78ea8c2a2f0a@o37442',
    projectId: '4505439353044992',
    releaseVersion: process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export let name = 'scheduled-task-manager-spa'
