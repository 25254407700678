import { SearchInput } from '@toasttab/buffet-pui-text-input'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { AllFilter, TaskFilter } from '../TaskFilter'
import { SelectMultiple } from '@toasttab/buffet-pui-dropdowns'
import { ScheduledTaskListItem } from '@local/interfaces'

export interface TaskListFilterControlsProps {
  testId?: string | number
  departments: string[]
  onFilterChanged: (filter: TaskFilter) => void
}

function searchBarFilter(searchBarValue: string): TaskFilter {
  return {
    filter: (task: ScheduledTaskListItem) =>
      task.name.toLowerCase().includes(searchBarValue.toLowerCase())
  }
}

function departmentSelectFilter(selectedDepartments: string[]): TaskFilter {
  return {
    filter(task: ScheduledTaskListItem): boolean {
      return (
        selectedDepartments.length === 0 ||
        task.departments.some((d) => {
          return selectedDepartments.includes(d)
        })
      )
    }
  }
}

export const TaskListFilterControls = ({
  testId,
  departments,
  onFilterChanged
}: TaskListFilterControlsProps) => {
  const [searchBarValue, setSearchBarValue] = useState<string>('')
  const [departmentSelectValue, setDepartmentSelectValue] = useState<string[]>(
    []
  )

  useEffect(() => {
    const searchFilter = searchBarFilter(searchBarValue)
    const departmentFilter = departmentSelectFilter(departmentSelectValue)
    const filter = new AllFilter([searchFilter, departmentFilter])
    onFilterChanged(filter)
  }, [onFilterChanged, searchBarValue, departmentSelectValue])

  return (
    <div data-testid={testId} className={'flex items-center w-full gap-1'}>
      <div className={'flex-grow'}>
        <SearchInput
          placeholder='Filter tasks by name...'
          value={searchBarValue}
          onChange={(e) => {
            setSearchBarValue(e.target.value)
          }}
        ></SearchInput>
      </div>
      <SelectMultiple
        testId={'department-select'}
        value={departmentSelectValue}
        onChange={(v) => {
          setDepartmentSelectValue(v)
        }}
        placeholder={'Filter tasks by department...'}
        options={departments}
      ></SelectMultiple>
    </div>
  )
}
