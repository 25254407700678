import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'

const httpLink = createHttpLink({
  uri: `${window.location.origin}/api/service/scheduled-task-manager/v1/graphql`
})

export const GraphQLClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})
