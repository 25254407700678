import * as React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ShowPageDetailsTable } from '@local/show-page-details-table'
import {
  LayoutProvider,
  Page,
  PageBody
} from '@toasttab/buffet-pui-config-templates'
import { ShowPageExecutionsTable } from '@local/show-page-executions-table'
import {
  isForbiddenError,
  isScheduledTaskManagerError,
  RUN_SCHEDULED_TASK,
  SCHEDULED_TASK,
  ScheduledTaskManagerError,
  ScheduledTaskManagerErrorCode
} from '@local/scheduled-task-manager-client'
import { AutoHideSpeed, useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useSentry } from 'banquet-runtime-modules'
import { Execution, ScheduledTaskShowItem } from '@local/interfaces'
import { useMutation, useQuery } from '@apollo/client'
import {
  ErrorBody403,
  ErrorBody404,
  ErrorBodyUnknown
} from '@local/error-bodies'
import { ShowPageHeader } from '../ShowPageHeader'

export interface ShowPageProps {
  testId?: string | number
}

interface ShowPageBodyProps {
  taskName: string
  isLoading: boolean
  executions: Execution[]
  onSuccessfulExecution: (execution: Execution) => void
  onUnsuccessfulExecution: (
    e: Error | ScheduledTaskManagerError | unknown
  ) => void
  showItem?: ScheduledTaskShowItem | undefined
}

const ShowPageBody = ({
  taskName,
  isLoading,
  executions,
  onSuccessfulExecution,
  onUnsuccessfulExecution,
  showItem
}: ShowPageBodyProps) => {
  const [runScheduledTask] = useMutation(RUN_SCHEDULED_TASK)
  const runTask = async (runName: string, issueId?: string, executionPayload?: string) => {
    await runScheduledTask({
      variables: {
        input: {
          taskName: taskName,
          runName: runName,
          issueId: issueId,
          executionPayload: executionPayload
        }
      },
      onCompleted: (data) => {
        if (data.runScheduledTask.execution) {
          onSuccessfulExecution(data.runScheduledTask.execution)
        } else {
          onUnsuccessfulExecution(data.runScheduledTask)
        }
      },
      onError: (error) => {
        onUnsuccessfulExecution(error)
      }
    })
  }

  return (
    <>
      <ShowPageDetailsTable
        testId={'show-page-details-table'}
        isLoading={isLoading}
        showItem={showItem}
        runTask={runTask}
      />
      <ShowPageExecutionsTable
        testId={'show-page-executions-table'}
        isLoading={isLoading}
        executions={executions}
        taskName={showItem?.name ?? ''}
      />
    </>
  )
}

interface ErrorPageBodyProps {
  error?: Error | ScheduledTaskManagerError | null
}

const ErrorPageBody = ({ error }: ErrorPageBodyProps) => {
  return !isScheduledTaskManagerError(error) ? (
    isForbiddenError(error) ? (
      <ErrorBody403 error={error} />
    ) : (
      <ErrorBodyUnknown error={error} />
    )
  ) : error?.code === ScheduledTaskManagerErrorCode.NotFound ? (
    <ErrorBody404 error={error} />
  ) : error?.code === ScheduledTaskManagerErrorCode.Forbidden ? (
    <ErrorBody403 error={error} />
  ) : (
    <ErrorBodyUnknown error={error} />
  )
}

/**
 * ShowPage component
 */
export const ShowPage = (_props: ShowPageProps) => {
  const params = useParams()
  const taskName = params.name ?? ''

  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const { captureException } = useSentry()
  const [errorPage, setErrorPage] = useState<
    Error | ScheduledTaskManagerError
  >()

  const { data, loading } = useQuery(SCHEDULED_TASK, {
    variables: { input: taskName },
    onError: (error) => {
      handleError(error)
    },
    onCompleted: (data) => {
      if (isScheduledTaskManagerError(data?.scheduledTask)) {
        handleError(data?.scheduledTask as ScheduledTaskManagerError)
      } else {
        setExecutions(data?.scheduledTask.executions ?? [])
      }
    }
  })

  /**
   * Default the executions state to the executions on the data. Necessary for when we visit the page when the query
   * is cached. Otherwise, the executions are empty, since they get set only in the query's onCompleted.
   */
  const [executions, setExecutions] = useState<Execution[]>(
    (data?.scheduledTask as ScheduledTaskShowItem)?.executions ?? []
  )
  const handleSuccessfulExecution = (execution: Execution) => {
    setExecutions([execution, ...executions])
    showSuccessSnackBar(
      `Task ${taskName} started with run name ${execution.name}.`,
      {
        isDismissable: true,
        autoHideDuration: AutoHideSpeed.Slow,
        testId: 'runScheduledTaskSuccessfulSnackBar'
      }
    )
  }
  const handleUnsuccessfulExecution = (
    e: Error | ScheduledTaskManagerError | unknown
  ) => {
    let message
    if (
      (e as ScheduledTaskManagerError).message !== undefined ||
      (e as Error).message !== undefined
    ) {
      message = `An error occurred while trying to run the task: ${
        (e as ScheduledTaskManagerError).message || (e as Error).message
      }`
    } else {
      message =
        'Unknown error occurred while trying to run the task. Try again.'
    }
    showErrorSnackBar(message, {
      isDismissable: true,
      autoHideDuration: AutoHideSpeed.Slow,
      testId: 'runScheduledTaskErrorSnackBar'
    })
  }

  return (
    <LayoutProvider disableMaxWidth>
      <Page>
        <ShowPageHeader
          taskName={taskName}
          repoUrl={
            (data?.scheduledTask as ScheduledTaskShowItem)?.repositoryUrl ?? ''
          }
        />
        <PageBody>
          {errorPage || isScheduledTaskManagerError(data?.scheduledTask) ? (
            <ErrorPageBody error={errorPage} />
          ) : (
            <ShowPageBody
              taskName={taskName}
              executions={executions}
              onSuccessfulExecution={handleSuccessfulExecution}
              onUnsuccessfulExecution={handleUnsuccessfulExecution}
              isLoading={loading}
              showItem={data?.scheduledTask as ScheduledTaskShowItem}
            />
          )}
        </PageBody>
      </Page>
    </LayoutProvider>
  )

  function handleError(error?: Error | ScheduledTaskManagerError) {
    setErrorPage(error)
    showErrorSnackBar(
      `An error has occurred while fetching a show page scheduled task: ${error?.message}`,
      {
        testId: 'getShowPageErrorSnackBar'
      }
    )
    captureException(
      new Error(`[Get scheduled task show item]: Error: ${error?.message}`)
    )
  }
}
