import React, { useState, useCallback } from 'react'
import cx from 'classnames'
import { Modal } from '@toasttab/buffet-pui-modal'
import {
  InfoIcon,
  CheckCircleSelectedIcon,
  CopyIcon
} from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'

export interface CopyProps {
  textToCopy: string
}

export interface CodeOutputProps {
  jsonStr: string
}

export interface ExecutionDetailsModalProps {
  isModalOpen: boolean
  executionPayload: string

  testId: string
}

export const Copy = (props: CopyProps) => {
  const [active, setActive] = useState(false)
  const tooltipContent = active ? <div>Copied!</div> : <div>Copy payload</div>

  return (
    <div>
      <Tooltip
        content={() => tooltipContent}
        variant={Tooltip.Variant.dark}
        className='inline-block float-right'
        placement='top'
      >
        <IconButton
          testId='copy-payload-button'
          icon={
            active ? (
              <CheckCircleSelectedIcon aria-label='Copied' />
            ) : (
              <CopyIcon aria-label='Copy' />
            )
          }
          onClick={() => {
            navigator.clipboard
              .writeText(props.textToCopy)
              .then(() => setActive(!active))
          }}
          textClassName={active ? 'text-success' : undefined}
        />
      </Tooltip>
    </div>
  )
}

function parseJson(jsonStr: string): string | undefined {
  try {
    return JSON.stringify(JSON.parse(jsonStr), null, 2)
  } catch (err) {
    return undefined
  }
}

export const CodeOutput = (props: CodeOutputProps) => {
  const json = parseJson(props.jsonStr)

  return (
    <div>
      <h3 className='mt-4 font-semibold type-overline'>Payload</h3>
      <div className='p-4 mb-8 mt-2 bg-gray-25 rounded'>
        {json && <Copy textToCopy={json} />}
        <code className='block type-caption overflow-x-auto overflow-y-auto'>
          {json === undefined ? (
            <pre className='italic'>undefined</pre>
          ) : (
            <pre>{json}</pre>
          )}
        </code>
      </div>
    </div>
  )
}

/**
 * ExecutionDetailsModal component
 */
export const ExecutionDetailsModal = (props: ExecutionDetailsModalProps) => {
  const [isOpen, setIsOpen] = useState(props.isModalOpen)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <div>
      <Modal isOpen={isOpen} onRequestClose={closeModal}>
        <Modal.Header>Execution Details</Modal.Header>
        <Modal.Body>
          <CodeOutput jsonStr={props.executionPayload}></CodeOutput>
        </Modal.Body>
      </Modal>
      <IconButton
        testId='open-execution-details-button'
        onClick={openModal}
        icon={<InfoIcon></InfoIcon>}
      ></IconButton>
    </div>
  )
}
