import { gql } from '@apollo/client'

export const SCHEDULED_TASK = gql`
  query ScheduledTask($input: String!, $maxExecutionResults: Int) {
    scheduledTask(taskName: $input) {
      ... on ScheduledTaskItem {
        name
        description
        readOnly
        departments
        executions(maxExecutionResults: $maxExecutionResults) {
          name
          startDateTime
          status
          revision
          arn
          payload
          user
        }
        githubTeamName
        repositoryUrl
        nextExecutionTimes
        cronExpression
      }
      ... on ScheduledTaskManagerError {
        code
        message
      }
    }
  }
`
