import { gql } from '@apollo/client'

export const RUN_SCHEDULED_TASK = gql`
  mutation RunScheduledTask($input: RunTaskInput!) {
    runScheduledTask(runTaskInput: $input) {
      ... on RunTaskResult {
        execution {
          name
          startDateTime
          status
          revision
          arn
          payload
          user
        }
      }
      ... on ScheduledTaskManagerError {
        code
        message
      }
    }
  }
`
