import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { Row, Cell } from '@toasttab/buffet-pui-table'
import React from 'react'

export interface SkeletonRowProps {
  cellCount: number
}

export const SkeletonRow = (props: SkeletonRowProps) => {
  let cells = []
  for (let i = 0; i < props.cellCount; i++) {
    cells.push(
      <Cell key={i}>
        <Skeleton testId={'skeleton'} className='w-full h-6' />
      </Cell>
    )
  }

  return <Row data-testid={'skeletonRow'}>{cells}</Row>
}
