import * as React from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { IconWrapper } from '@toasttab/buffet-pui-icons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import cx from 'classnames'
import { manifestUpdateText } from '@local/content-common'

export interface TadpoleIconButtonProps {
  testId?: string | number
  repoName: string
}

const TadpoleIcon = ({ className }: any) => {
  return (
    <IconWrapper className={cx('inline-block float-right h-12', className)}>
      <img
        height={32}
        width={32}
        src='https://cdn.toasttab.com/static/f6f13400bf1aa60334bc67215844fede6bdc5e81/projects/tadpole/logo.png'
        alt='tadpole spa versions'
      />
    </IconWrapper>
  )
}

function tadpoleLink(repoName: string): string {
  return `https://tdp.eng.toasttab.com/${repoName}/overview/`
}

/**
 * TadpoleIconButton component
 */
export const TadpoleIconButton = ({ repoName }: TadpoleIconButtonProps) => {
  if (repoName == 'UNKNOWN') {
    return (
      <Tooltip content={() => manifestUpdateText('repositoryUrl', 'this link')}>
        <IconButton
          testId={'tadpole-icon-button'}
          as='a'
          icon={<TadpoleIcon />}
          target='_blank'
          rel='noopener noreferrer'
        />
      </Tooltip>
    )
  } else {
    return (
      <IconButton
        testId={'tadpole-icon-button'}
        as='a'
        icon={<TadpoleIcon />}
        href={tadpoleLink(repoName)}
        target='_blank'
        rel='noopener noreferrer'
      />
    )
  }
}
