import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { ListPage } from '../../packages/list-page'
import { GraphQLClient } from '@local/scheduled-task-manager-client'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'
import { ShowPage } from '../../packages/show-page'
import { BanquetProps } from 'banquet-runtime-modules'

export const ROUTES = createRoutesFromElements(
  <Route path='/'>
    <Route path='/' element={<ListPage />} />
    <Route path=':name' element={<ShowPage />} />
  </Route>
)

export const router = createBrowserRouter(ROUTES, {
  basename: '/scheduled-task-manager'
})

export function App(props?: BanquetProps) {
  return (
    <ApolloProvider client={GraphQLClient}>
      <SnackBarProvider>
        <RouterProvider router={router} />
      </SnackBarProvider>
    </ApolloProvider>
  )
}
