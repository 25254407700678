import { ScheduledTaskListItem } from '@local/interfaces'

export interface TaskFilter {
  filter(task: ScheduledTaskListItem): boolean
}

export const TRUE_FILTER: TaskFilter = {
  filter(_: ScheduledTaskListItem): boolean {
    return true
  }
}
