import * as React from 'react'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { ScheduledTaskManagerError } from '@local/scheduled-task-manager-client'

export interface ErrorBody404Props {
  error: ScheduledTaskManagerError
}

/**
 * ErrorBody404 component
 */
export const ErrorBody404 = (props: ErrorBody404Props) => {
  return (
    <EmptyState icon={<WarningOutlineIcon />}>
      Sorry, we were unable to load this page.
      <p className='font-mono'>{JSON.stringify(props.error.message)}</p>
    </EmptyState>
  )
}
