import * as React from 'react'
import { ListRow, SkeletonRow } from '@local/list-row'
import { ScheduledTaskListItem } from '@local/interfaces'
import { ScheduledTaskManagerError } from '@local/scheduled-task-manager-client'

export interface ListTableProps {
  testId?: string | number
  loading: boolean
  tasks: ScheduledTaskListItem[]
  error?: ScheduledTaskManagerError | Error | null
}

/**
 * ListTable component
 */

export const ListTable = ({ loading, tasks }: ListTableProps) => {
  return loading ? (
    <SkeletonRow cellCount={4} />
  ) : (
    <>
      {tasks.map((task: ScheduledTaskListItem) => (
        <ListRow key={task.name} scheduledTask={task} />
      ))}
    </>
  )
}
