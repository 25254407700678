import { ApolloError } from '@apollo/client'
import { ScheduledTaskManagerError, ScheduledTaskManagerErrorCode } from '.'
import { ScheduledTaskListItem } from '@local/interfaces'

export interface AllScheduledTasksResult {
  results: [ScheduledTaskListItem]
}

export interface AllScheduledTasksResponse {
  scheduledTasks: AllScheduledTasksResult | ScheduledTaskManagerError
}

export function isScheduledTaskManagerError(
  response: any
): response is ScheduledTaskManagerError {
  return (
    (response as ScheduledTaskManagerError) !== undefined &&
    (response as ScheduledTaskManagerError).code !== undefined
  )
}

export function isForbiddenError(
  response: any
): response is ScheduledTaskManagerError {
  const error = response as ApolloError
  return (
    error !== undefined &&
    error.graphQLErrors !== undefined &&
    error.graphQLErrors[0]?.extensions?.code ===
      ScheduledTaskManagerErrorCode.Forbidden
  )
}

export function isScheduledTasksResult(
  response: any
): response is AllScheduledTasksResponse {
  return (
    (response as AllScheduledTasksResponse) !== undefined &&
    ((response as AllScheduledTasksResponse)
      .scheduledTasks as AllScheduledTasksResult) !== undefined &&
    response.scheduledTasks.results !== undefined
  )
}
