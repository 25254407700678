import * as React from 'react'
import { Row, Cell } from '@toasttab/buffet-pui-table'
import { ScheduledTaskListItem } from '@local/interfaces'
import {
  CheckboxSelectedIcon,
  CheckboxUnselectedIcon
} from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { Link } from 'react-router-dom'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { manifestUpdateText } from '@local/content-common'

export interface DepartmentCellProps {
  departmentList: string[]
}

export interface ListRowProps {
  scheduledTask: ScheduledTaskListItem
}

export const DepartmentCell = ({ departmentList }: DepartmentCellProps) => {
  if (departmentList.includes('Department Unknown')) {
    return (
      <Cell>
        <div className='flex flex-row'>
          <p className='text-slate-500'>Unknown</p>
          <InfoTooltip icon={<InfoIcon size={'xs'} />}>
            {manifestUpdateText('department', "'Unknown'")}
          </InfoTooltip>
        </div>
      </Cell>
    )
  }
  return <Cell>{departmentList.join(', ')}</Cell>
}

/**
 * ListRow component
 */
export const ListRow = ({ scheduledTask }: ListRowProps) => {
  if (!scheduledTask.name) {
    return null
  }

  if (scheduledTask.readOnly) {
    return (
      <Row data-testid='scheduledTaskRow' className='align-right'>
        <Cell>
          <Button as={Link} to={scheduledTask.name} variant='link'>
            {scheduledTask.name}
          </Button>
        </Cell>
        <Cell>{scheduledTask.description}</Cell>
        <DepartmentCell departmentList={scheduledTask.departments} />
        <Cell className='text-center'>
          <CheckboxUnselectedIcon
            data-testid={'unselectedCheckbox'}
            className='text-disabled'
          ></CheckboxUnselectedIcon>
        </Cell>
      </Row>
    )
  }
  return (
    <Row data-testid='scheduledTaskRow' className='align-right'>
      <Cell>
        <Button as={Link} to={scheduledTask.name} variant='link'>
          {scheduledTask.name}
        </Button>
      </Cell>
      <Cell>{scheduledTask.description}</Cell>
      <DepartmentCell departmentList={scheduledTask.departments} />
      <Cell data-testid={'selectedCheckbox'} className='text-center'>
        <CheckboxSelectedIcon className='text-disabled'></CheckboxSelectedIcon>
      </Cell>
    </Row>
  )
}
