import * as React from 'react'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ScheduledTaskManagerError } from '@local/scheduled-task-manager-client'

export interface ErrorBody403Props {
  error: ScheduledTaskManagerError
}

/**
 * ErrorBody403 component
 */
export const ErrorBody403 = (props: ErrorBody403Props) => {
  return (
    <EmptyState icon={<WarningOutlineIcon />} testId='403ErrorBody'>
      <p className='font-mono'>{JSON.stringify(props.error.message)}</p>
      <p className='font-mono'>
        <Button
          as='a'
          variant='text-link'
          className='font-mono'
          href={
            'https://toasttab.atlassian.net/wiki/spaces/TSC/pages/3610313466/Runbook+Trigger+Scheduled+Task+Executions#Permissions'
          }
          target={'_blank'}
        >
          More details
        </Button>{' '}
        on how to get Scheduled Tasks Page permissions
      </p>
    </EmptyState>
  )
}
