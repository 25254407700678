import * as React from 'react'
import {useState} from 'react'
import * as Yup from 'yup'
import {Modal} from '@toasttab/buffet-pui-modal'
import {Form, Formik} from 'formik'
import {CancelButton, SubmitButton, TextInputField} from '@toasttab/buffet-pui-forms'
import {Button} from '@toasttab/buffet-pui-buttons'
import {Tooltip} from '@toasttab/buffet-pui-tooltip'
import {getCurrentEnvironment, ToastEnvironment} from '@toasttab/current-environment'

export interface RunTaskModalProps {
  isModalOpen: boolean
  readOnly: boolean
  runTask: (runName: string, issueId?: string, executionPayload?: string) => Promise<void>

  testId?: string | number
}

export interface RunTaskFormValues {
  runName: string
  issueId?: string
  executionPayload?: string
}

interface ModalBodyProps {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
}

const env = getCurrentEnvironment()

const instructions = () => {
  return (
    <a
      className='inline-link'
      target='_blank'
      style={{ fontSize: '14px' }}
      rel='noreferrer'
      href={
        'https://toasttab.atlassian.net/wiki/spaces/TSC/pages/3610313466/Runbook+Trigger+Scheduled+Task+Executions'
      }
    >
      Instructions
    </a>
  )
}

const RunModalBody = ({ handleSubmit }: ModalBodyProps) => {
  return (
    <Modal.Body>
      { instructions() }
      <Form
        id='run-task-form'
        data-testid={'run-task-form'}
        onSubmit={handleSubmit}
      >
        <RunNameInput />
        <IssueIdInput />
        <RunPayloadInput />
      </Form>
    </Modal.Body>
  )
}

const RunNameInput = () => {
  return (
    <div className='mt-2'>
      <TextInputField
        data-testid={'runNameTextBox'}
        name='runName'
        label='Run Name'
        helperText='The name of your run. Must be unique.'
      />
    </div>
  )
}

const IssueIdInput = () => {
  // We only require the Jira issue id in PROD. Adding it in DEV is solely for running tests.
  if (env === ToastEnvironment.PROD || env === ToastEnvironment.DEV) {
    return (
      <div className='mt-5'>
        <TextInputField
          data-testid={'issueIdTextBox'}
          name='issueId'
          label='Jira Ticket Number'
          placeholder={'TOST-100'}
          helperText={
              'A Jira ticket with a Code Reviewer is required for SOX compliance.'
          }
        />
      </div>
    )
  } else {
      return (<div/>)
  }
}

const RunPayloadInput = () => {
  return (
    <div className='mt-5'>
      <TextInputField
        data-testid={'runPayloadTextBox'}
        name='executionPayload'
        label='Execution Payload'
        placeholder={'(Optional)'}
        helperText='Optional payload passed to your execution via environment variable. See instructions.'
      />
    </div>
  )
}

interface ModalFooterProps {
  isSubmitting: boolean
  closeModal: () => void
}

const RunModalFooter = ({ isSubmitting, closeModal }: ModalFooterProps) => {
  return (
    <Modal.Footer>
      <CancelButton className='flex-grow sm:flex-none' onClick={closeModal}>
        Cancel
      </CancelButton>
      <SubmitButton
        testId={'submit-button'}
        isInProgress={isSubmitting}
        className='flex-grow sm:flex-none'
        type='submit'
        form='run-task-form'
      >
        Run
      </SubmitButton>
    </Modal.Footer>
  )
}

export function validationSchema() {
  return Yup.object({
    runName: Yup.string()
      .required('Required.')
      .max(80, 'Must be less than or equal to 80 characters.')
      .matches(
        /^[a-zA-Z0-9-_]*$/,
        'Must be only alphanumerics, dashes and underscores.'
      ),
    issueId: Yup.string().when([], {
      is: () => env === ToastEnvironment.PROD || env === ToastEnvironment.DEV,
      then: (schema) =>
        schema
          .max(20, 'Must be less than or equal to 20 characters.')
          .matches(/^[a-zA-Z0-9-]*$/, 'Must be only alphanumerics and dashes.')
          .required('Required.'),
      otherwise: (schema) => schema
    })
  })
}

interface RunTaskButtonProps {
  readOnly: boolean
  openModal: () => void
}

const RunTaskButton = ({ readOnly, openModal }: RunTaskButtonProps) => {
  if (readOnly) {
    let tooltipContent =
      'You do not have the necessary permissions to run this task. You must have the ' +
      'SCHEDULED_TASK_WRITE admin permission and be in the same department as the task.'
    return (
      <Tooltip content={() => tooltipContent}>
        <Button
          testId={'run-task-modal-button'}
          disabled={readOnly}
          onClick={openModal}
        >
          Run Task
        </Button>
      </Tooltip>
    )
  } else {
    return (
      <Button
        testId={'run-task-modal-button'}
        disabled={readOnly}
        onClick={openModal}
      >
        Run Task
      </Button>
    )
  }
}

/**
 * RunTaskModal component
 */
export const RunTaskModal = (props: RunTaskModalProps) => {
  const [isOpen, setIsOpen] = useState(props.isModalOpen)
  let initialValues = {
    runName: ''
  }
  const [formValues] = useState<RunTaskFormValues>(initialValues)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema()}
      onSubmit={async (values, { resetForm }) => {
        await props.runTask(values.runName, values.issueId, values.executionPayload)
        closeModal()
        resetForm()
      }}
    >
      {(formikProps) => (
        <>
          <Modal isOpen={isOpen} onRequestClose={closeModal}>
            <Modal.Header>Run Task</Modal.Header>
            <RunModalBody handleSubmit={formikProps.handleSubmit} />
            <RunModalFooter
              isSubmitting={formikProps.isSubmitting}
              closeModal={closeModal}
            />
          </Modal>
          <RunTaskButton openModal={openModal} readOnly={props.readOnly} />
        </>
      )}
    </Formik>
  )
}
