import * as React from 'react'
import {
  HeadingGroup,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { TadpoleIconButton } from '../TadpoleIconButton'
import { GithubIconButton } from '../GithubIconButton'

export interface ShowPageHeaderProps {
  taskName: string
  repoUrl: string
}

function repoNameFromRepoUrl(repoUrl: string): string {
  let urlComponents = repoUrl.split('/')
  // handle urls that do or do not have a trailing slash
  return urlComponents.reverse().find((component) => component.length > 0) ?? ''
}

/**
 * ShowPageHeader component
 */
export const ShowPageHeader = ({ taskName, repoUrl }: ShowPageHeaderProps) => {
  return (
    <PageHeader>
      <HeadingGroup className={'flex'}>
        <Title>{taskName}</Title>
      </HeadingGroup>
      <GithubIconButton repoUrl={repoUrl} />
      <TadpoleIconButton repoName={repoNameFromRepoUrl(repoUrl)} />
    </PageHeader>
  )
}
