export function manifestUpdateText(field: string, fixes: string) {
  return (
    "Update the '" +
    field +
    "' field in your service manifest and deploy to fix " +
    fixes
  )
}

export function formatDepartment(department: string) {
  if (department.includes(' ')) return department
  let words = department.toLowerCase().replace('_and_', '_&_').split('_')
  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substr(1)
    })
    .join(' ')
}
