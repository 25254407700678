import * as React from 'react'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { ScheduledTaskManagerError } from '@local/scheduled-task-manager-client'

export interface ErrorBodyUnknownProps {
  error?: ScheduledTaskManagerError | Error | null
}

/**
 * ErrorBodyUnknown component
 */
export const ErrorBodyUnknown = (props: ErrorBodyUnknownProps) => {
  return (
    <EmptyState testId={'generic-error-body'} icon={<WarningOutlineIcon />}>
      Sorry, we were unable to load this page. Please{' '}
      <a className='text-link' href='/'>
        reload this page
      </a>
      .<p className='font-mono'>{JSON.stringify(props.error?.message)}</p>
    </EmptyState>
  )
}
