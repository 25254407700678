import { TaskFilter } from './TaskFilter'
import { ScheduledTaskListItem } from '@local/interfaces'

export class AllFilter implements TaskFilter {
  private filters: TaskFilter[]

  constructor(filters: TaskFilter[]) {
    this.filters = filters
  }

  filter(task: ScheduledTaskListItem): boolean {
    return this.filters.every((f) => f.filter(task))
  }
}
