import * as React from 'react'
import {Execution} from '@local/interfaces'
import {Cell, Row} from '@toasttab/buffet-pui-table'
import {InfoTooltip, Tooltip} from '@toasttab/buffet-pui-tooltip'
import {formatDistance} from '@toasttab/buffet-pui-date-utilities'
import {
  AutorenewIcon,
  CheckCircleSelectedFillIcon,
  ChevronRightIcon,
  InfoIcon,
  LaunchIcon,
  WarningFilledIcon
} from '@toasttab/buffet-pui-icons'
import {getCurrentEnvironment, ToastEnvironment} from '@toasttab/current-environment'
import {IconButton} from '@toasttab/buffet-pui-buttons'
import {ExecutionDetailsModal} from '@local/execution-details-modal'

export interface ShowPageExecutionsRowProps {
  testId?: string | number
  execution: Execution
  taskName: string
}

/**
 * ShowPageExecutionsRow component
 */
export const ShowPageExecutionsRow = (props: ShowPageExecutionsRowProps) => {
  const { testId, execution, taskName } = props
  const revisionToolTip =
    execution.revision === null || execution.revision === undefined ? (
      <InfoTooltip icon={<InfoIcon size={'xs'} />}>
        Fix this by updating the name field on the terraform module “my-app” to
        $&#123;module.metadata.all["stack.name"]&#125;
      </InfoTooltip>
    ) : (
      <></>
    )
  return (
    <Row data-testid={testId}>
      <Cell>
        <a
          href={buildExecutionUrl(execution.arn)}
          className='inline-link'
          target='_blank'
          rel='noreferrer'
        >
          {execution.name}
          <LaunchIcon size='xs' className='ml-1 mb-1 align-middle' />
        </a>
        {execution.user !== undefined && execution.user !== null ? (
          <span>
            <br />
            Triggered by {execution.user}
          </span>
        ) : (
          ''
        )}
      </Cell>
      {startTimeCell(execution)}
      <Cell>
        {execution.revision}
        {revisionToolTip}
      </Cell>
      {statusCell(execution)}
      {splunkCell(taskName, execution)}
      {executionDetailsCell(execution)}
    </Row>
  )
}

function startTimeCell(execution: Execution) {
  return (
    <Cell>
      <Tooltip
        placement={'top-start'}
        content={() => execution.startDateTime.toString()}
      >
        {formatDistance({
          date1: new Date(execution.startDateTime),
          date2: new Date(),
          locale: 'en-US'
        })}{' '}
        ago
      </Tooltip>
    </Cell>
  )
}

function statusCell(execution: Execution) {
  if (execution.status === 'SUCCEEDED') {
    return (
      <Cell className={'text-success font-medium'}>
        <CheckCircleSelectedFillIcon
          testId={'success-icon'}
        ></CheckCircleSelectedFillIcon>
        <span>{execution.status}</span>
      </Cell>
    )
  } else if (execution.status === 'FAILED') {
    return (
      <Cell className={'text-error font-bold'}>
        <WarningFilledIcon testId={'failure-icon'}></WarningFilledIcon>
        <span>{execution.status}</span>
      </Cell>
    )
  } else if (execution.status === 'RUNNING') {
    return (
      <Cell className={'font-medium'}>
        <AutorenewIcon testId={'running-icon'}></AutorenewIcon>
        <span>{execution.status}</span>
      </Cell>
    )
  } else if (execution.status === 'PENDING') {
    return (
      <Cell className={'font-medium'}>
        <AutorenewIcon testId={'running-icon'}></AutorenewIcon>
        <span>{execution.status}</span>
      </Cell>
    )
  } else {
    return (
      <Cell>
        <span>{execution.status}</span>
      </Cell>
    )
  }
}

const regionRegex = '^(?:[^:]+:){3}([^:]+).*'
export function buildExecutionUrl(arn: string) {
  let match = arn.match(regionRegex)
  let region
  if (match !== null && match.length > 1) {
    region = match[1]
  } else {
    region = 'us-east-1'
  }
  return `https://${region}.console.aws.amazon.com/states/home?region=${region}#/executions/details/${arn}`
}

const splunkBaseUrl = 'https://toast.splunkcloud.com/en-US/app/search/search'

function splunkCell(taskName: string, execution: Execution) {
  const hour = 60 * 60 * 1000 // default time-window of an hour
  let env = getCurrentEnvironment()
  let envString
  let shortEnvString

  switch (env) {
    case ToastEnvironment.PREPROD:
      envString = 'preproduction'
      shortEnvString = 'pre'
      break;
    case ToastEnvironment.SANDBOX:
      envString = env
      shortEnvString = 'sbx'
      break;
    default:
      envString = env
      shortEnvString = env
  }
  const splunkTimeQuery = `?earliest=${
    new Date(execution.startDateTime).getTime() / 1000
  }&latest=${(new Date(execution.startDateTime).getTime() + hour) / 1000}`
  const splunkSearchQuery = `&q=search index=${envString}_g2 source=${shortEnvString}-${taskName}-${execution.revision ?? ''}*`

  return (
    <Cell>
      <IconButton
        as='a'
        testId='logs-icon'
        href={splunkBaseUrl + splunkTimeQuery + splunkSearchQuery}
        target='_blank'
        icon={<ChevronRightIcon className={undefined} />}
        rel='noopener noreferrer'
      >
        Logs
      </IconButton>
    </Cell>
  )
}

function executionDetailsCell(execution: Execution) {
  return (
    <Cell>
      <ExecutionDetailsModal
        testId='execution-details-modal'
        isModalOpen={false}
        executionPayload={execution.payload ?? ''}
      ></ExecutionDetailsModal>
    </Cell>
  )
}
