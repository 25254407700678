import * as React from 'react'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { DepartmentCell, SkeletonRow } from '@local/list-row'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { RunTaskModal } from '@local/run-task-modal'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { ScheduledTaskShowItem } from '@local/interfaces'
import { Link } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { formatDepartment, manifestUpdateText } from '@local/content-common'

export interface ShowPageDetailsTableProps {
  testId?: string | number
  isLoading: boolean
  showItem?: ScheduledTaskShowItem
  runTask: (runName: string, issueId?: string, executionPayload?: string) => Promise<void>

  // todo function to update executions
}

/**
 * ShowPageDetailsTable component
 */
export const ShowPageDetailsTable = ({
  isLoading,
  showItem,
  runTask
}: ShowPageDetailsTableProps) => {
  const formattedDepartments = showItem
    ? showItem.departments.map((d) => formatDepartment(d))
    : []

  const showGithubTeamTooltip =
    showItem?.githubTeamName == 'UNKNOWN' ? (
      <InfoTooltip icon={<InfoIcon size={'xs'} />}>
        {manifestUpdateText('githubTeam', "'UNKNOWN'")}
      </InfoTooltip>
    ) : (
      <></>
    )
  return isLoading || showItem == null
    ? detailsTableWithBody(<SkeletonRow cellCount={6} />)
    : detailsTableWithBody(
        <Row data-testid='scheduledTaskShowItemRow' className='align-right'>
          <Cell>{showItem.description}</Cell>
          <DepartmentCell departmentList={formattedDepartments} />
          <Cell>
            <Button
              as={Link}
              to={
                'https://github.com/orgs/toasttab/teams/' +
                showItem.githubTeamName?.substring(
                  showItem.githubTeamName?.indexOf('/') + 1
                )
              }
              target='_blank'
              variant='text-link'
            >
              {showItem.githubTeamName}
              {showGithubTeamTooltip}
            </Button>
          </Cell>
          <Cell>
            <div style={{ whiteSpace: 'nowrap', fontFamily: 'monospace' }}>
              {showItem.cronExpression}
            </div>
          </Cell>
          <Cell>{nextExecutionTime(showItem.nextExecutionTimes)}</Cell>
          <Cell>
            <RunTaskModal
              isModalOpen={false}
              readOnly={showItem.readOnly}
              runTask={runTask}
            ></RunTaskModal>
          </Cell>
        </Row>
      )
}

function nextExecutionTime(nextExecutionTimes: Array<Date>) {
  if (nextExecutionTimes.length > 0) {
    return new Date(nextExecutionTimes[0]).toString()
  }
  return ''
}

function detailsTableWithBody(bodyData: any) {
  return (
    <Panel>
      <h1 className={'type-headline-4'}>Task Details</h1>
      <div
        data-testid={'show-page-details-table-body'}
        className='p-4 space-y-2 type-default'
      >
        <Table className='table table-striped table-bordered'>
          <Head data-testid='taskTableHeader'>
            <Row>
              <HeadingCell>Description</HeadingCell>
              <HeadingCell>Departments</HeadingCell>
              <HeadingCell>Github Team</HeadingCell>
              <HeadingCell>Cron Schedule</HeadingCell>
              <HeadingCell>Next Execution</HeadingCell>
              <HeadingCell testId={'run-task-header'}>
                Run Task
                <InfoTooltip icon={<InfoIcon size={'xs'} />}>
                  You must have the SCHEDULED_TASK_WRITE admin permission and be
                  in the same department in order to run a task.
                </InfoTooltip>
              </HeadingCell>
            </Row>
          </Head>
          <Body>{bodyData}</Body>
        </Table>
      </div>
    </Panel>
  )
}
